import { useState } from "react";
import { Dialog } from "./Dialog";
import { TextField } from "./TextField";
import { MagicIcon, OpenAIIcon } from "./icons";
import { FilledButton } from "./FilledButton";
import { CheckboxItem } from "./CheckboxItem";
import { KEYS } from "../keys";
import { useUIAppState } from "../context/ui-appState";
import { InlineIcon } from "./InlineIcon";
import { Paragraph } from "./Paragraph";

import "./MagicSettings.scss";
import TTDDialogTabs from "./TTDDialog/TTDDialogTabs";
import { TTDDialogTab } from "./TTDDialog/TTDDialogTab";

export const MagicSettings = (props: {
  openAIKey: string | null;
  isPersisted: boolean;
  onChange: (key: string, shouldPersist: boolean) => void;
  onConfirm: (key: string, shouldPersist: boolean) => void;
  onClose: () => void;
}) => {
  const [keyInputValue, setKeyInputValue] = useState(props.openAIKey || "");
  const [shouldPersist, setShouldPersist] = useState<boolean>(
    props.isPersisted,
  );

  const appState = useUIAppState();

  const onConfirm = () => {
    props.onConfirm(keyInputValue.trim(), shouldPersist);
  };

  if (appState.openDialog?.name !== "settings") {
    return null;
  }

  return (
    <Dialog
      onCloseRequest={() => {
        props.onClose();
        props.onConfirm(keyInputValue.trim(), shouldPersist);
      }}
      title={
        <div style={{ display: "flex" }}>
          Wireframe to Code (AI){" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.1rem 0.5rem",
              marginLeft: "1rem",
              fontSize: 14,
              borderRadius: "12px",
              background: "var(--color-promo)",
              color: "var(--color-surface-lowest)",
            }}
          >
            Experimental
          </div>
        </div>
      }
      className="MagicSettings"
      autofocus={false}
    >
      {/*  <h2
        style={{
          margin: 0,
          fontSize: "1.25rem",
          paddingLeft: "2.5rem",
        }}
      >
        AI Settings
      </h2> */}
      <TTDDialogTabs dialog="settings" tab={appState.openDialog.tab}>
        {/* <TTDDialogTabTriggers>
          <TTDDialogTabTrigger tab="text-to-diagram">
            <InlineIcon icon={brainIcon} /> Text to diagram
          </TTDDialogTabTrigger>
          <TTDDialogTabTrigger tab="diagram-to-code">
            <InlineIcon icon={MagicIcon} /> Wireframe to code
          </TTDDialogTabTrigger>
        </TTDDialogTabTriggers> */}
        {/* <TTDDialogTab className="ttd-dialog-content" tab="text-to-diagram">
          TODO
        </TTDDialogTab> */}
        <TTDDialogTab
          //  className="ttd-dialog-content"
          tab="diagram-to-code"
        >
          <Paragraph>
            对于线框图至代码的功能，我们使用{" "}
            <InlineIcon icon={OpenAIIcon} />
            OpenAI.
          </Paragraph>
          <Paragraph>
            虽然 OpenAI API 仍处于测试阶段，其使用受到严格限制——因此我们要求您使用自己的 API 密钥。您可以创建一个{" "}
            <a
              href="https://api.2sj.ai/"
              rel="noopener noreferrer"
              target="_blank"
            >
              OpenAI 账户
            </a>
            , 添加少量信用（最低 5 美元），然后{" "}
            <a
              href="https://api.2sj.ai/token"
              rel="noopener noreferrer"
              target="_blank"
            >
              生成您自己的 API 密钥。
            </a>
            .
          </Paragraph>
          <Paragraph>
            您的 OpenAI 密钥不会离开浏览器，如果需要，您还可以在 OpenAI 帐户仪表板中设置自己的限制。
          </Paragraph>
          <TextField
            isRedacted
            value={keyInputValue}
            placeholder="Paste your API key here"
            label="OpenAI API key"
            onChange={(value) => {
              setKeyInputValue(value);
              props.onChange(value.trim(), shouldPersist);
            }}
            selectOnRender
            onKeyDown={(event) => event.key === KEYS.ENTER && onConfirm()}
          />
          <Paragraph>
            默认情况下，您的 API 令牌不会在任何地方持久存储，因此在重新加载后需要再次插入。不过，您可以在浏览器下方本地持久化。
          </Paragraph>

          <CheckboxItem checked={shouldPersist} onChange={setShouldPersist}>
            在浏览器存储中持久化 API 密钥。
          </CheckboxItem>

          <Paragraph>
            一旦设置了 API 密钥，您可以使用 <InlineIcon icon={MagicIcon} />{" "}
            工具将您的元素包裹在一个框架中，然后将其转换为代码。可以使用以下方式访问此对话框：{" "}
            <b>AI Settings</b> <InlineIcon icon={OpenAIIcon} />.
          </Paragraph>

          <FilledButton
            className="MagicSettings__confirm"
            size="large"
            label="Confirm"
            onClick={onConfirm}
          />
        </TTDDialogTab>
      </TTDDialogTabs>
    </Dialog>
  );
};
